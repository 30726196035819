import React, { useState } from "react"
import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap"
import Swal from "sweetalert2"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMailBulk } from "@fortawesome/free-solid-svg-icons"
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons"
// import ReCAPTCHA from "react-google-recaptcha"
// const ReCAPTCHA = lazy(() => import("react-google-recaptcha"))
const ReCAPTCHA = React.lazy(() => import("react-google-recaptcha"))
const TEST_SITE_KEY = "6Lf9Xr0ZAAAAAMrA3JaUPX_2uxzrSF_2btvHogwi"

const ContactUs = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState({})
  const [state, setState] = React.useState({
    callback: "not fired",
    value: "",
    expired: false,
  })
  const _reCaptchaRef = React.createRef()
  const isSSR = typeof window === "undefined"

  const handleSubmit = () => {
    if (!validate()) return

    let body
    body = new FormData(body)
    body.append("name", name)
    body.append("email", email)
    body.append("message", message)

    fetch("https://api.intermediamarketinggroup.com/index.php", {
      method: "POST",
      mode: "no-cors",
      "Access-Control-Allow-Origin": "*",
      body,
    })
      .then(() => {
        setEmail("")
        setMessage("")
        setName("")
        Swal.fire({
          title: "Thank You",
          text: "We will contact you soon.",
          icon: "success",
          confirmButtonText: "Okay",
        })
      })
      .catch(() => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong at our end. Please try again.",
          icon: "error",
          confirmButtonText: "Okay",
        })
      })
  }

  const validate = () => {
    const errors = {}
    if (_.isEmpty(name)) errors.name = "Name can not be empty"
    if (!email.includes("@")) errors.email = "Please enter a valid email"
    if (_.isEmpty(email)) errors.email = "Email can not be empty"
    if (_.isEmpty(message)) errors.message = "Message can not be empty"
    if (_.isEmpty(state.value))
      errors.recaptcha = "Please verify that you are not a robot"
    setError(errors)
    return _.isEmpty(errors)
  }

  const remvoeError = ({ target: { name } }) => {
    setError({ ...error, [name]: "" })
  }

  const handleChange = value => {
    setError({ ...error, recaptcha: "" })
    setState({ value })
    if (value === null) setState({ expired: true })
  }

  const asyncScriptOnLoad = () => {
    setState({ callback: "called!" })
  }

  return (
    <div className="contact-us-section py-5" id="contact-us">
      <h2 className="text-center letter-spacing-4 mb-5">GET IN TOUCH</h2>
      <Row className="m-0 wrapper contact-us-wrapper">
        <Col xs={12} md={6} className="pr-md-0">
          <div className="p-4 p-md-5 justify-content-center d-flex flex-column contact-form">
            <h4 className="text-color-secondary">Send us a Message</h4>
            <Form className="mt-4">
              <FormGroup>
                <label htmlFor="name">Name</label>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  onFocus={remvoeError}
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                  // placeholder="Your Name"
                />
                {error.name ? (
                  <span className="text-danger">{error.name}</span>
                ) : (
                  <span>&nbsp;</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="email">Email</label>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  // placeholder="Email"
                  value={email}
                  onFocus={remvoeError}
                  onChange={({ target: { value } }) => setEmail(value)}
                />
                {error.email ? (
                  <span className="text-danger">{error.email}</span>
                ) : (
                  <span>&nbsp;</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="message">Message</label>
                <Input
                  id="message"
                  type="textarea"
                  name="message"
                  rows="5"
                  // placeholder="Message"
                  className="text-area"
                  value={message}
                  onFocus={remvoeError}
                  onChange={({ target: { value } }) => setMessage(value)}
                />
                {error.message ? (
                  <span className="text-danger">{error.message}</span>
                ) : (
                  <span>&nbsp;</span>
                )}
              </FormGroup>
              <FormGroup>
                {!isSSR && (
                  <React.Suspense fallback={<div/>}>
                    <ReCAPTCHA
                      theme="light"
                      ref={_reCaptchaRef}
                      sitekey={TEST_SITE_KEY}
                      onChange={handleChange}
                      asyncScriptOnLoad={asyncScriptOnLoad}
                      id="recaptcha"
                      className="red"
                    />
                  </React.Suspense>
                )}
                {error.recaptcha ? (
                  <span className="text-danger">{error.recaptcha}</span>
                ) : (
                  <span>&nbsp;</span>
                )}
              </FormGroup>
              <Button
                className="mt-3 w-100"
                size="md"
                color="primary"
                onClick={handleSubmit}
              >
                Send Message
              </Button>
            </Form>
          </div>
        </Col>
        <Col xs={12} md={6} className="pl-md-0">
          <div className="p-4 p-md-5 w-100 text-light bg-primary h-100">
            <h5>Contact Details</h5>
            <div className="mt-5">
              <div className="d-flex align-items-center mt-4">
                <FontAwesomeIcon icon={faLocationArrow} />
                <span className="ml-3">
                  1300 CORPORATE CENTER WAY SUITE # 105C WELLINGTON, FL 33414
                </span>
              </div>
              <div className="d-flex align-items-center mt-4">
                <FontAwesomeIcon icon={faMailBulk} />
                <a
                  href="mailto:info@intermediamarketinggroup.com"
                  className="text-light ml-3"
                  style={{ overflowWrap: "anywhere" }}
                >
                  info@intermediamarketinggroup.com
                </a>
              </div>
              <div className="mt-5 mb-5 d-flex justify-content-center w-100"></div>
            </div>
            <div className="p-3" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ContactUs
