import React from "react"
import { Link } from "gatsby"
import Image from "../image"

const Consulting = () => {
  return (
    <div className="consulting-section py-5" id="consulting">
      <h2 className="text-center letter-spacing-4 mb-5">CONSULTING</h2>
      <div className="row wrapper m-0">
        <div className="col-12">
          <Image src="CONSULTANCY.webp" />
        </div>
      </div>
      <div className="row wrapper m-0">
        <div className="col-12 consulting-content">
          <p className="line-height-2 mt-3">
            Intermedia Marketing Group offers expertise on a consulting basis in
            the following areas: New website design Redesign existing websites
            Implement Search Engine Optimization (SEO) structure Expand social
            media reach on{" "}
            <Link to="/consulting" className="text-primary">
              Read More
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Consulting
