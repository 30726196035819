import React from "react"
import Image from "../image"

const AboutUs = () => {
  return (
    <div className="py-5" id="about-us">
      <h2 className="text-center  letter-spacing-4 mb-5">ABOUT US</h2>
      <div className="row m-0 wrapper about-wrapper">
        <div className="col-lg-6 col-sm-6">
          <div className="about-image">
            <Image src="about1.webp" />
          </div>
          <h4 className="mt-4 mb-3 font-weight-bold">
            Intermedia Marketing Group Intro
          </h4>
          <p className="line-height-1 text-justify">
            Intermedia Marketing Group is your total solution for online and
            mobile marketing. Our network of industry partners offer a wide
            range of expertise and together bring Intermedia Marketing Groups
            clients only the best service with the best results.
          </p>
        </div>
        <div className="col-lg-6 col-sm-6">
          <div className="about-image">
            <Image src="about3.webp" />
          </div>
          <h4 className="mt-4 mb-3 font-weight-bold">Our Services</h4>
          <p className="line-height-1 text-justify">
            Intermedia Marketing Group services are intended for companies
            looking to expand their online presence and generate profitable
            revenue from online advertising. Customizing each campaign,
            Intermedia Marketing Group can place ads all across the web
            including banners, email,search engines, affiliates, social media,
            and mobile.
          </p>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
