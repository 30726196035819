import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLaptop } from "@fortawesome/free-solid-svg-icons"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

const Features = () => {
  return (
    <div className="features-section py-5" id="features">
      <h2 className="text-center letter-spacing-4">HOW WE DO IT</h2>
      <div className="row m-0 wrapper py-5">
        <div className="feature-item col-lg-4 col-sm-6">
          <div className="feature-media">
            <FontAwesomeIcon icon={faLaptop} />
          </div>
          <h5 className="text-center font-weight-bold">Media Buying</h5>
          <div className="feature-item-content">
            <p>
              Intermedia Marketing Group customizes interactive media plans with
              clients for attracting first time customers, growing an existing
              customer base, or establishing more brand awareness. Intermedia
              Marketing Group has proven success working within CPA, CPC, CPV or
              CPM media buys on several advertising networks including:
            </p>
            <ul>
              <li>Right Media</li>
              <li>AdEx</li>
              <li>AdECN</li>
              <li>Turn</li>
              <li>AdBrite</li>
              <li>Facebook</li>
            </ul>
          </div>
        </div>
        <div className="feature-item col-lg-4 col-sm-6">
          <div className="feature-media">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <h5 className="text-center font-weight-bold">Search Engines</h5>
          <div className="feature-item-content">
            <p>
              Search engines are one of the top performing advertising vehicles
              because consumers are seeking more information using specific
              keywords. Google, Yahoo, and Bing are the three largest online
              search engines, and we utilize these plus a few others to place
              targeted ads in front of prospective customers.
            </p>
            <ul>
              <li>Keyword research</li>
              <li>Landing page design</li>
              <li>Ad copywriting</li>
              <li>Keyword bid strategy</li>
              <li>Tracking &amp; reporting KPIs</li>
              <li>Campaign optimization</li>
            </ul>
          </div>
        </div>
        <div className="feature-item col-lg-4 col-sm-6 mlr-auto">
          <div className="feature-media">
            <FontAwesomeIcon icon={faPhone} />
          </div>
          <h5 className="text-center font-weight-bold">Pay Per Call</h5>
          <div className="feature-item-content ">
            <p>
              Per Per Call is the booming way of advertisement and lead
              generation. It's the favorite of customers as they get instant
              quotes, real-time communication and answer to their questions. It
              offers incremental growth and high returns compared to
              conventional ways of advertising.
            </p>
            <ul>
              <li>Keyword Research</li>
              <li>Launch a new program</li>
              <li>We provide inbound calls to your business</li>
              <li>Engage your customers instantly over the phone</li>
              <li>Providing influential deals in real-time</li>
              <li>
                Guide customers through the complex process and decision making
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
