import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import Image from "../image"

const Websites = () => {
  return (
    <div className="websites-section py-5" id="websites">
      <h2 className="text-center letter-spacing-4 mb-5">OUR ASSETS</h2>
      <div className="row m-0 wrapper">
        <div className="col-xs-12 col-md-6">
          <Link to="u-save-services">
            <Image src="usave.webp" />
          </Link>
        </div>
        <div className="col-xs-12 col-md-6 mt-3 mt-md-0 d-flex align-items-center">
          <div className="websites-content">
            <Link to="u-save-services">
              <h3>USaveServices.com</h3>
            </Link>
            <span>
              U Save Services is a business to business solution. The following
              are the things we are proud of.
            </span>
            <ul>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>9000+ merchants</h6>
              </li>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>Nationwide supplier</h6>
              </li>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>Extensive collection of Products</h6>
              </li>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>Services covering more than 70 categories</h6>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-xs-12 col-md-6 order-2 order-md-1 mt-3 mt-md-5 d-flex align-items-center">
          <div className="websites-content">
            <Link to="your-repair-experts">
              <h3>YourRepairExperts.com</h3>
            </Link>
            <span>
              Your Repair Experts let you find local professionals for your home
              or business project
            </span>
            <ul>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>Instant Quote</h6>
              </li>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>Up to 4 pro`s quote</h6>
              </li>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>Vast network of venders</h6>
              </li>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>100+ categories</h6>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xs-12 col-md-6 order-1 order-md-2 mt-3 mt-md-5">
          <Link to="your-repair-experts">
            <Image src="yourrepairexperts.PNG" />
          </Link>
        </div>

        <div className="col-xs-12 col-md-6 mt-3 mt-md-0 d-flex align-items-center order-4 order-md-4">
          <div className="websites-content">
            <Link to="superior-merchants">
              <h3>SuperiorMerchants.com</h3>
            </Link>
            <span>
              Superior Merchants help businesses to grow by providing them all
              services under one roof.
            </span>
            <ul>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>Huge number of merchants</h6>
              </li>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>Nationwide supplier</h6>
              </li>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>Quality driven services</h6>
              </li>
              <li className="d-flex">
                <FontAwesomeIcon icon={faCheckCircle} />
                <h6>Best suitable for businesses</h6>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xs-12 col-md-6 order-3 order-md-3 mt-3 mt-md-5">
          <Link to="superior-merchants">
            <Image src="superior-merchants.png" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Websites
