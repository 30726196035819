import React from "react"
import { Button } from "reactstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import BackgroundImage from "../bgImage"

const Banner = () => {
  return (
    <div className="banner" id="home">
      <BackgroundImage src="banner.webp">
        <div className="banner-wrapper text-light text-center py-5 px-2 d-flex flex-column justify-content-center align-items-center">
          <h1 className="font-weight-bolder letter-spacing-4 text-center">
            WE ARE INTERMEDIA MARKETING GROUP
          </h1>
          <h5 className="font-italic font-weight-lighter mt-4 text-center">
            YOUR COMPLETE SOLUTION FOR{" "}
            <span className="font-weight-bold">ONLINE</span> AND{" "}
            <span className="font-weight-bold">MOBILE MARKETING</span>
          </h5>
          <div className="inline mt-4">
            <AnchorLink to="/#about-us" stripHash>
              <Button color="primary" className="mr-md-3">
                Our Services
              </Button>
            </AnchorLink>
            <AnchorLink to="/#contact-us" stripHash>
              <Button color="secondary" className="mt-3 mt-md-0">
                Get Started
              </Button>
            </AnchorLink>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default Banner
