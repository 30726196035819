import React from "react"
import { Link } from "gatsby"

const Services = () => {
  return (
    <div className="services-section py-5" id="services">
      <h2 className="text-center letter-spacing-4 mb-5">WHAT WE DO </h2>
      <div className="row m-0 wrapper">
        <div className="col-12">
          <Link to="/customer-acquisition" className="text-decoration-none">
            <div className="services-content p-3 p-md-5">
              <h4 className="services-title text-dark">Customer Acquisition</h4>
              <p className="line-height-2">
                Customer Acquisition is at the heart of almost every business
                today and is vital to establishing market share. Generating
                leads is a highly effective way to promote your product or
                service, and establish relationships with potential customers
                right before they are ready to buy. Through precise demographic
                targeting, Intermedia Marketing Group helps clients place ads in
                front of prospective customers. Designing ads that generate
                interest and demand is part of our secret sauce, but after that,
                we go to work placing them across multiple channels.
              </p>
              <ul>
                <li>CPM Banner Ad Networks</li>
                <li>Search Engine Marketing (SEM)</li>
                <li>Affiliate &amp; Agency Partners</li>
                <li>Social Media Placements</li>
                <li>Direct Site Buy</li>
                <li>Pay Per Call</li>
              </ul>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Services
