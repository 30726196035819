import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/home/banner"
import Features from "../components/home/features"
import AboutUs from "../components/home/about-us"
import Services from "../components/home/services"
import Consulting from "../components/home/consulting"
import ContactUs from "../components/home/contact-us"
import Websites from "../components/home/websites"

const Index = props => (
  <Layout>
    <SEO title="Home" description="" />
    <Banner />
    <Features />
    <AboutUs />
    <Services /> 
    <Consulting />
    <Websites />
    <ContactUs/> 
  </Layout>
)

export default Index
